import styles from './Price.module.scss';

const Price = () => {
    return(
        <div className={styles.container}>
            <div className={styles.container__heading}>
                <h1>Cene parkinga</h1>
            </div>
            <div className={styles.container__text}>
                <p>Cena započetog časa je <span>150 RSD/h </span> (PDV je uključen u cenu).</p>
                <p>Cena dnevne karte je <span>2000 RSD </span>(PDV je uključen u cenu), za zaposlene u kliničkom centru <span>850 RSD</span> (PDV je uključen u cenu). </p>
                <p>Cena mesečne karte je <span>10 000 RSD </span>(PDV je uključen u cenu), za zaposlene u kliničkom centru <span>8 000 RSD </span>(PDV je uključen u cenu). </p>
                <p>Cena mesečne karte za zaposlene u kliničkom centru sa stubićem na daljinski je <span>10 000 RSD </span>(PDV je uključen u cenu).</p>

            </div>
        </div>
    );
}

export default Price;
