import ContacrForm from "../../components/ContactForm/ContactForm";
import ContactHeader from "../../components/ContactHeader/ContactHeader";
import Promotion from "../../components/Promotion/Promotion";

const ContactPage = () => {
    return(
        <>
          <ContactHeader/>
          <Promotion/>
          <ContacrForm/>
        </>
    );
}

export default ContactPage;