import styles from './Advertise.module.scss'
import { Link } from 'react-router-dom';

const Advertise = () => {
    return(
        <div className={styles.container}>
            <div className={styles.container__content}>
                <h1>Ukoliko ste zainteresovani da logo Vaše firme bude istaknut u našem parking kompleksu možete nas kontaktirati.</h1>
                <Link to="/contact">
                    <button>Reklamiraj se</button>
                </Link> 
            </div>
        </div>
    )
};

export default Advertise;