import Header from '../../components/Header/Header';
import Amenities from '../../components/Amenities/Amenities';
import Location from '../../components/Location/Location';
import Price from '../../components/Price/Price';
import Advertise from '../../components/Adverstise/Advertise';

const HomePage = () => {
    return(
        <>
          <Header />
          <Amenities />
          <Location />
          <Price />
          <Advertise />
        </>
    );
}

export default HomePage;