import styles from "./Header.module.scss";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className={styles.container}>
      <div className={styles.container__content}>
        <h1>Garaža Resavska Beograd</h1>
        <Link to="/contact">
          <button>Reklamiraj se</button>
        </Link>
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
};

export default Header;