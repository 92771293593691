import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <div className={styles.container}>
            <p>Parking Resavska 2024</p>
        </div>
    );
}

export default Footer;