import styles from "./Amenities.module.scss";
import parking from '../../images/parking.png';
import fullDay from '../../images/24h.png';
import stairs from '../../images/stairs.png';
import invalid from '../../images/invalid.png';
import camera from '../../images/camera.png';

const Amenities = () => {
  return (
    <div className={styles.container}>
        <div className={styles.container__heading}>
            <h1>Naš parking Vam nudi</h1>
            <h2>Naš parking u centru grada nudi širok spektar pogodnosti.</h2>
        </div>
        <div className={styles.container__content}>
            <div className={styles.container__content__item}>
                <div className={styles.container__content__item__box}>
                    <img src={parking} alt="parking" />
                </div>
                <p>Ukupno 639 parking mesta</p>
            </div>
            <div className={styles.container__content__item}>
                <div className={styles.container__content__item__box}>
                    <img src={fullDay} alt="24h" />
                </div>
                <p>Pristup 24/7</p>
            </div>
           <div className={styles.container__content__item}>
            <div className={styles.container__content__item__box}>
                <img src={stairs} alt="6 nivoa" />
            </div>
            <p>Ukupno 6 nivoa</p>
           </div>
           <div className={styles.container__content__item}>
                <div className={styles.container__content__item__box}>
                    <img src={invalid} alt="mesta za invalide" />
                </div>
                <p>Parking mesta za osobe sa invaliditetom</p>
           </div>
           <div className={styles.container__content__item}>
                <div className={styles.container__content__item__box}>
                    <img src={camera} alt="video nadzor" />
                </div>
                <p>Video nadzor 24/7</p>
           </div>
        </div>
    </div>
  );
};

export default Amenities;