import styles from './ContactForm.module.scss';
import { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";



const ContacrForm = () => {
    const [alert, setAlert] = useState(false);
    const [status, setStatus] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm(
          "service_bud2l36",
          "template_aij5ebi",
          e.target,
          "K_hH9depceLEf-9Sz"
        )
        .then(
          (success) => {
            setAlert(success);
          },
          (error) => {
            setStatus(error);
          }
        );
      e.target.reset();
    };

    useEffect(() => {
      setTimeout(() => {
        setAlert(false);
        setStatus(false);
      }, 5500);
    }, [alert]);

    return (
        <div className={styles.container}>
            <h1>Kontakt forma</h1>
            <form className={styles.container__form} onSubmit={sendEmail}>
                <div className={styles.container__form__user}>
                    <div className={styles.container__form__user__item}>
                        <label>Ime i prezime</label>
                        <input name='user' type="text" placeholder='Ime i prezime' required/>
                    </div>
                    <div className={styles.container__form__user__item}>
                        <label>Mail adresa</label>
                        <input name='email' type="email" placeholder='name@mail.com' required/>
                    </div>
                </div>
                <div className={styles.container__form__message}>
                    <label htmlFor="">Poruka</label>
                    <textarea  name="message" id="" cols="30" rows="10" placeholder='Vaša poruka'></textarea>
                </div>
                <button type='submit'>Pošalji poruku</button>
            </form>
            {alert && (
        <Alert
          severity="success"
          className={`${styles.container__alert} ${
            alert
              ? styles["container__alert--active"]
              : styles["container__alert--not-active"]
          }`}
        >
          E-mail succesfully sent!
        </Alert>
      )}
      {status && (
        <Alert
          severity="error"
          className={`${styles.container__alert} ${
            status
              ? styles["container__alert--active"]
              : styles["container__alert--not-active"]
          }`}
        >
          An error occurred, try again later!
        </Alert>
      )}
        </div>
    );
}

export default ContacrForm;
