import styles from './Location.module.scss'

const Location = () => {
  return (
   <div className={styles.container}>
    <div className={styles.container__heading}>
        <h1>Lokacija u srcu grada</h1>
        <h2>Adresa: Resavska</h2>
    </div>
    <div className={styles.container__text}>
        <p>
            Garaža UKCS Resavska je smeštena u samom srcu grada, tik uz Klinički centar Srbije, čineći je jednom od najkorisnijih garaža u urbanom okruženju. Sa 6 nivoa i preko 630 parking mesto, ova garaža nudi obilje praktičnih opcija za parkiranje.
        </p>
        <p>
            Urbanog izgleda, moderna i funkcionalna, garaža UKCS Resavska je neizbežna stanica za vozače u gradu. Bez obzira da li ste u poseti Beogradu kao turista ili živite u njemu, ova garaža je idealna baza za istraživanje grada.
        </p>
    </div>
    <div className={styles.map}>
        <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2831.0974858361715!2d20.45337787633006!3d44.7992018775621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a71ad35ebd9e1%3A0x3ecbac55a1eec0b0!2z0JPQsNGA0LDQttCwINCa0LvQuNC90LjRh9C60L7QsyDQptC10L3RgtGA0LAg0KHRgNCx0LjRmNC1!5e0!3m2!1ssr!2srs!4v1707989237855!5m2!1ssr!2srs" 
            allowFullScreen
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
            title='Garaza Klinickog Centra Srbije'></iframe>
    </div>
   </div>
  );
};

export default Location;