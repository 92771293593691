import styles from './Promotion.module.scss'
import brendingImg from '../../images/brending.png';
import garageImg from '../../images/garage.jpg';

const Promotion = () => {
    return (
        <div className={styles.container}>
            <div className={styles.container__text}>
                <p>Ako želite da se logo Vaše kompanije ili bilo koji promotivni materijal istakne u našem parking kompleksu, slobodno nas kontaktirajte, a mi ćemo Vam dostaviti ponudu.</p>
                <p>Svakog dana kroz našu garažu prođe u proseku više od 1500 vozila. Trenutno imamo na raspolaganju 6 parking nivoa i preko 600 parking mesta. Postoji mogućnost zakupa celog sprata i opremanja promotivnim materijalom prema Vašim zahtevima.</p>
                <p>Osim unutrašnjosti, na raspolaganju Vam je i spoljašnja fasada sa površinom od čak 2700m2. Ukoliko ste zainteresovani za iznajmljivanje prostora za brendiranje, slobodno nas kontaktirajte putem kontakt forme.</p>
            </div>
            <div className={styles.container__images}>
                <img src={brendingImg} alt="brending" />
                <img src={garageImg} alt="garaža" />
            </div>
        </div>
    );
}

export default Promotion;