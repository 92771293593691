import styles from './ContactHeader.module.scss';
import { Link } from 'react-router-dom';


const ContactHeader = () => {
    return (
        <div className={styles.container}>
        <div className={styles.container__content}>
            <Link to="/"><p>Vrati se na početnu stranicu.</p></Link>
            <h1>Pošaljite upit za reklamu</h1>
        </div>
    </div>
    );
}

export default ContactHeader;